<template>
  <v-container>
    
    <div class="d-flex flex-row justify-between align-center">
      <h3>
        {{ $t("message.title-applied-merchant") }}
      </h3>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- Routing to Approve list -->
      <template>
        <router-link to="/approvedMerchant">
          <v-row>
            <v-btn rounded color="primary">
              {{ $t("message.btn-approved-list") }}
            </v-btn>
          </v-row>
        </router-link>
      </template>
    </div>

    <!-- Card Nav -->
    <div class="card-merchant">
      <v-card color="primary">
        <v-card-text>
          <span class="text-merchant">{{$t("message.title-merchant")}}</span>
        </v-card-text>
      </v-card>
    </div>
    <!-- <template>
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="12" md="6" xs="2" class="card-merchant">
              <v-card color="primary">
                <v-card-text>
                  <div class="text">
                    <span class="text text-merchant">{{
                      $t("message.title-merchant")
                    }}</span>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template> -->

    <v-pagination
    v-model="page"
    :length="lengthPage"
    :total-visible="7"
    @input="changePage"
    depressed
    ></v-pagination>

    <!-- Data Table -->
    <v-data-table
      :headers="headers"
      :items="resultApplied"
      :hide-default-footer="true"
      
      :items-per-page="appliedListHeader.per_page"
      class="elevation-0"
      disable-sort
      justify="center"
      :server-items-length="appliedListHeader.total"
      :options.sync="option"
      :footer-props="{
        'items-per-page-options': [ appliedListHeader.per_page != null ? appliedListHeader.per_page : 5, ],
      }"
      :page="appliedListHeader.currentPage"
    >
      <!-- action button merchant list -->
      <template v-slot:[`item.action`]="{ item }">
        <v-btn rounded @click.prevent="buttonAllow(item.id)">
          {{$t("message.btn-approved-allow")}}
        </v-btn>
        <v-btn rounded @click.prevent="buttonDeny(item.id)">
          {{$t("message.btn-approved-deny")}}
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  name: "AppliedMerchant",
  data: () => ({
    page: 1,
    option: {},
  }),
  methods: {
    changePage(event) {
      return this.$store.dispatch("applied_module/fetchApplied", {
        page: event,
      })
    },
    dispatchApplied() {
      let setPage = parseInt(this.$store.state.applied_module.pageState)
      this.page = parseInt(this.$store.state.applied_module.pageState)
      return this.$store.dispatch("applied_module/fetchApplied", {
        page: setPage,
      });
    },
    buttonAllow(id) {
      let statusAllow = 1;
      return this.$store.dispatch("applied_module/patchAllowDeny", {
        id: id,
        status: statusAllow,
      });
    },
    buttonDeny(id) {
      let statusDeny = 0;
      return this.$store.dispatch("applied_module/patchAllowDeny", {
        id: id,
        status: statusDeny,
      });
    },
  },
  computed: {
    lengthPage() {
      return this.$store.state.applied_module.length_page
    },
    resultApplied() {
      return this.$store.state.applied_module.resultApplied;
    },
    appliedListHeader() {
      return this.$store.state.applied_module.totalPage
    },
    headers() {
      return [
        {
          text: this.$t("message.label-company") ,
          align: "start",
          sortable: false,
          value: "company_name",
        },
        { text: this.$t("message.label-pic"), value: "pic_name", sortable: false },
        { text: this.$t("message.label-phone"), value: "phone", sortable: false },
        { text: this.$t("message.label-address"), value: "address", sortable: false },
        { text: this.$t("message.label-action"), value: "action", sortable: false, width: "200px" },
      ];
    },
  },
  created() {
    this.dispatchApplied();
  },
  watch: {
    option: {
      handler() {
        this.dispatchApplied();
      },
      deep: true,
    },
    appliedList: {
      handler() {
        this.page = parseInt(this.$store.state.applied_module.pageState)
      }
    },
    deep: true,
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  background: #c5cae9 !important;
}

.v-application--is-ltr .v-data-footer__select {
  margin-left: 0px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.text-merchant {
  font-weight: bold;
  font-size: 16px;
  color: white;
}
.card-merchant {
  width: fit-content !important;
  margin-top: 15px;
}
.v-card > .v-card__text {
  padding: 8px !important;
  margin-bottom: 10px !important;
}
.v-card {
  width: fit-content !important;
}
.v-data-footer-top {
  border-top: none !important;
}
.v-data-footer__select {
  margin-left: 0px !important;
}
</style>
